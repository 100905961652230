'use client';

import React, {
  FC,
  Fragment,
  HTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMeasure, useWindowScroll, useWindowSize } from 'react-use';
import { Icon } from '../icons';
import { MdClose, MdOutlineFormatListBulleted, MdSearch } from 'react-icons/md';
import { CustomLink } from '../link';
import Link from 'next/link';
import { Button } from '../button';
import QueryString from 'qs';
import { useSearchProducts } from 'stores/search/ProductSearch';
import { usePathname, useRouter } from 'next/navigation';
import { useNavigation } from 'stores/header-navigation';
import ModalComponent from '../modal/ModalComponent';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import { ProductCategories } from 'stores/types';

interface ProductsNavigation {
  name?: string | any;
  slug?: string | any;
}

interface Props<T> extends HTMLAttributes<T> {
  variant?: string | 'default' | 'primary' | 'secondary' | 'danger';
  position?: string | 'fixed' | '';
  className?: string;
  textColor?: string;
  logoImages?: string;
  navigationTextColor?: string;
  colorHeader?: string;
}

interface DrawerProps {
  id: number;
  isOpen: boolean;
}

interface NavigationItem {
  id: number;
  name: string;
  url?: string;
  dropdown: boolean;
}

const Navbar: FC<Props<Element>> = ({ ...props }) => {
  const axios = AxiosInstance.create({
    baseURL: process.env.API_ENDPOINT,
  });
  const [productCategories, setProductCategories] = useState<
    ProductCategories[] | any[]
  >([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);
  const [isSelectedCategory, setIsSelectedCategory] = useState<string>('');
  const [isDropdownProducts, setIsDropdownProducts] = useState<boolean>(false);
  const router = useRouter();
  const pathname = usePathname();
  const { navigations, getNavigations } = useNavigation();
  const { products, getSearchProduct, isError, isLoading, meta } =
    useSearchProducts();
  const [dataProducts, setDataProducts] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [resetColorNavbar, setResetColorNavbar] = useState<boolean>(false);
  const [isDrawer, setIsDrawer] = useState<DrawerProps[] | any[]>();
  const [removeFix, setRemoveFix] = useState<boolean>(true);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [dataProduct, setDataProduct] = useState<any>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [prevId, setPrevId] = useState<any>(null);
  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setDataProducts([]);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const [ref, component] = useMeasure<HTMLHeadElement>();
  const { width } = useWindowSize();

  const { y } = useWindowScroll();

  const clientHeight = useMemo(() => {
    let h = '0px';
    if (width > 640) {
      h = `${component.height}px`;
    }
    return h;
  }, [component.height, width]);

  const scrollNavbar = useMemo(() => {
    let background: string =
      'fixed transition-all duration-[0.1ms] md:duration-[300ms] w-full top-0 start-0 before:-top-5 before:start-0 before:h-5 animate-fade-in-down bg-white/90 text-gray-600 backdrop-blur-sm shadow-sm';
    if (y && y > 150) {
      return {
        background,
        color: 'text-gray-700',
      };
    } else {
      return {
        background: '',
        color: '',
      };
    }
  }, [y]);

  const filters = useMemo(() => {
    let search = QueryString.stringify({
      sort: ['id:desc'],
      filters: {
        name: {
          $containsi: inputValue || '',
        },
      },
      fields: ['name', 'description', 'slug', 'createdAt', 'new_release'],
      pagination: {
        pageSize: 5,
        page: 1,
      },
      populate: {
        thumbnail: '*',
      },
    });
    let parse = QueryString.parse(search);
    return parse;
  }, [inputValue]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop < 30) {
        setRemoveFix(true);
      } else {
        setRemoveFix(false);
      }
      if (currentScrollTop > lastScrollTop) {
        // Scrolling down
        setIsVisible(false);
      } else {
        // Scrolling up
        setIsVisible(true);
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    if (filters && inputValue.length > 1) {
      const timeOut = setTimeout(() => {
        getSearchProduct({ params: filters });
      }, 1000);
      return () => clearTimeout(timeOut);
    }
  }, [filters]);

  const filterNavigation = useMemo(() => {
    let search = QueryString.stringify({
      populate: {
        list: {
          populate: {
            products: {
              populate: '*',
            },
          },
        },
      },
    });
    let parse = QueryString.parse(search);
    return parse;
  }, []);

  useEffect(() => {
    getNavigations({ params: filterNavigation });
  }, [filterNavigation]);

  useEffect(() => {
    let filter: DrawerProps[] | any[] = [];
    if (navigations?.attributes?.list?.length > 0) {
      navigations?.attributes?.list
        ?.filter((item: any) => item?.dropdown)
        .map((items: any) => {
          filter.push({
            ...items,
            isOpen: false,
          });
        });
    }
    setIsDrawer(filter);
  }, [navigations]);

  const onOpenDrawer = (
    id: number | string,
    item?: NavigationItem,
    draw?: DrawerProps,
    productDropdown?: any
  ): void => {
    const prevId = dataProduct?.id;
    if (id !== dataProduct?.id) {
      if (id === 'all') {
        setDataProduct({ id: 'all' });
      } else {
        setResetColorNavbar(!resetColorNavbar);
        setDataProduct({
          id,
          prevId,
          productDropdown: isDrawer?.find((item) => item?.id === id)?.products
            ?.data,
          draw: isDrawer?.find((item) => item?.id === id),
          header: item,
        });

        let filter = isDrawer?.find((items) => items?.id === id);
        setIsDrawer(
          isDrawer?.map((item) =>
            item?.id === id
              ? { ...item, isOpen: true }
              : { ...item, isOpen: false }
          )
        );
      }
    } else {
      setPrevId(prevId);
      setDataProduct(null);
      setIsDrawer(isDrawer?.map((item) => ({ ...item, isOpen: false })));
    }
  };

  const prefixCategories = '/product-categories';
  const getProductCategories = async (params?: AxiosRequestConfig) => {
    setIsLoadingCategories(true);
    try {
      const response = await axios.get(prefixCategories, params);
      setProductCategories(response.data.data);
      setIsLoadingCategories(false);
    } catch (error) {
      console.error('Error fetching product-categories:', error);
      setIsLoadingCategories(false);
    }
  };

  const filterCategory = useMemo(() => {
    let search = QueryString.stringify({
      // populate: "*"
      sort: ['position:asc'],
      populate: {
        products: {
          populate: '*',
        },
      },
    });
    let parse = QueryString.parse(search);
    return parse;
  }, []);

  useEffect(() => {
    if (filterCategory) getProductCategories({ params: filterCategory });
  }, [filterCategory]);

  useEffect(() => {
    const _selected = productCategories?.find((item) =>
      item?.attributes?.products?.data?.some((item: any) =>
        pathname?.includes(item?.attributes?.slug)
      )
    );
    if (_selected?.attributes?.slug) {
      setIsSelectedCategory(_selected?.attributes?.slug);
    } else {
      setIsSelectedCategory(productCategories[0]?.attributes?.slug);
    }
  }, [pathname, productCategories]);

  const productSelected = useMemo(() => {
    let filter =
      productCategories?.length > 0 &&
      productCategories?.find(
        (item) => item?.attributes?.slug === isSelectedCategory
      );
    return filter?.attributes?.products?.data;
  }, [productCategories, isSelectedCategory]);

  useEffect(() => {
    if (inputValue.length > 0) {
      setDataProducts(products);
    }
  }, [products]);

  useEffect(() => {
    setResetColorNavbar(isDropdownProducts);
  }, [isDropdownProducts]);

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Fragment>
      <header
        ref={ref}
        className={`mrcool-navbar-header header-sticky z-50 w-full bg-white text-sm ${props.className} ${!resetColorNavbar && removeFix ? props.colorHeader : ''} ${isVisible ? 'visible-sticky' : 'hidden-sticky'}`}
        style={
          removeFix
            ? { position: 'relative', transition: 'ease-in', zIndex: 1 }
            : { position: 'fixed', zIndex: 1000 }
        }
      >
        <nav
          className='mx-auto flex w-full max-w-[90rem] flex-wrap px-4 py-2 lg:flex-nowrap lg:items-center lg:justify-between lg:py-0'
          aria-label='Global'
        >
          <div className="flex w-full items-center justify-between lg:w-auto lg:justify-start lg:flex-grow-0">
            <Link
              href={{ pathname: '/' }}
              scroll={true}
              className='mrcool-navbar-logo flex-none text-xl font-semibold lg:py-3 lg:pr-5'
            >
              <img
                src={
                  scrollNavbar.color || resetColorNavbar
                    ? '/images/mrcool-logo.svg'
                    : props.logoImages && !scrollNavbar.color && removeFix
                      ? props.logoImages
                      : '/images/mrcool-logo.svg'
                }
                alt='logo'
                className="mrcool-navbar-logo-img h-10 w-full max-w-40 max-[800px]:content-[url('/images/mrcool-logo.svg')]"
              />
            </Link>

            <div className='mrcool-navbar-actions flex items-center gap-x-2 lg:hidden'>
              <Button
                onClick={onOpen}
                variant='custom'
                type='button'
                className='mrcool-navbar-search-btn inline-flex items-center gap-x-2 px-1 py-1 text-sm font-medium text-gray-600 hover:text-gray-400 disabled:pointer-events-none disabled:opacity-50'
              >
                <Icon
                  icon={MdSearch}
                  className={`mrcool-navbar-search-icon size-5 ${!scrollNavbar.color ? props.textColor : scrollNavbar.color} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
                />
              </Button>

              <button
                type='button'
                className='mrcool-navbar-mobile-menu-btn inline-flex items-center justify-center gap-x-2 rounded-lg border border-gray-200 bg-white p-2.5 text-gray-800 shadow-sm hover:bg-gray-50 disabled:pointer-events-none disabled:opacity-50 dark:bg-transparent'
                onClick={toggleMobileMenu}
                aria-expanded={isMobileMenuOpen}
                aria-label='Toggle navigation'
              >
                <Icon
                  icon={isMobileMenuOpen ? MdClose : MdOutlineFormatListBulleted}
                  className='h-4 w-4 flex-shrink-0'
                />
              </button>
            </div>
          </div>

          <div
            className={`mrcool-navbar-menu basis-full lg:basis-auto lg:flex lg:flex-grow ${isMobileMenuOpen ? 'block' : 'hidden'}`}
          >
            <div className='mrcool-navbar-menu-inner flex flex-col items-end gap-4 mt-4 lg:mt-0 lg:flex-row lg:items-center lg:justify-center lg:w-full'>
              <button
                onClick={() =>
                  prevId === 'all' ? setPrevId(null) : onOpenDrawer('all')
                }
                className={`mrcool-navbar-all-products-btn inline-flex font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 ${isDropdownProducts ? 'border-b-2 border-primary font-bold text-primary' : ''} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
              >
                All Products
              </button>
              {navigations?.attributes?.list?.length > 0 &&
                navigations?.attributes?.list?.map((header: NavigationItem, index: number) => {
                  if (!header?.dropdown) {
                    return (
                      <CustomLink
                        key={index}
                        href={{ pathname: header?.url || '/' }}
                        pages={header?.name?.toLowerCase() || ''}
                        variant='primary-header'
                        className={`mrcool-navbar-menu-link ${scrollNavbar.color ? scrollNavbar.color : props.textColor} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
                      >
                        {header?.name || ''}
                      </CustomLink>
                    );
                  } else {
                    let productDropdown = isDrawer?.find(
                      (item) => item?.isOpen
                    )?.products?.data;
                    return (
                      <button
                        key={index}
                        onClick={() =>
                          header?.id === prevId
                            ? setPrevId(null)
                            : onOpenDrawer(
                                header?.id,
                                header,
                                isDrawer?.find((item): item is DrawerProps => item?.id === header?.id),
                                productDropdown
                              )
                        }
                        className={`mrcool-navbar-dropdown-btn inline-flex font-medium text-gray-600 hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-500 ${isDrawer && isDrawer[index]?.isOpen ? 'border-b-2 border-primary font-bold text-primary' : ''} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
                      >
                        {header?.name || ''}
                      </button>
                    );
                  }
                })}
            </div>
          </div>

          <div className='mrcool-navbar-actions hidden items-center gap-x-2 lg:flex lg:ml-auto lg:pl-2'>
            <Button
              onClick={onOpen}
              variant='custom'
              type='button'
              className='mrcool-navbar-search-btn inline-flex items-center gap-x-2 px-1 py-1 text-sm font-medium text-gray-600 hover:text-gray-400 disabled:pointer-events-none disabled:opacity-50'
            >
              <Icon
                icon={MdSearch}
                className={`mrcool-navbar-search-icon size-5 ${!scrollNavbar.color ? props.textColor : scrollNavbar.color} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
              />
            </Button>

            <Link
              href={{ pathname: 'https://mrcool.com/portal' }}
              target='_blank'
            >
              <Button
                variant='custom'
                className='mrcool-navbar-partner-btn rounded-full border-gray-300 px-3 py-1 text-sm border'
              >
                <span
                  className={`mrcool-navbar-partner-text ${scrollNavbar.color ? scrollNavbar.color : props.textColor} ${!resetColorNavbar && removeFix ? props?.navigationTextColor : ''}`}
                >
                  Partner Center
                </span>
              </Button>
            </Link>
          </div>
        </nav>
      </header>

      {/* Product dropdown modal */}
      {dataProduct && dataProduct?.id !== 'all' ? (
        <ModalComponent
          isOpen={dataProduct || false}
          close={() =>
            onOpenDrawer(dataProduct?.header?.id, dataProduct?.header)
          }
          className='mrcool-product-dropdown-modal z-1000 min-h-full rounded-b-none rounded-t-none sm:min-h-fit'
          containerClass='h-full sm:max-h-full'
          size={'full'}
          position='top'
          transition={width > 620 ? 'top' : 'left'}
          style={{
            top: clientHeight,
          }}
        >
          <div className='mrcool-product-dropdown-content w-full'>
            <div className='mrcool-product-dropdown-grid relative mx-auto grid h-full w-full max-w-[85rem] grid-cols-1 gap-4 pb-6 sm:grid-cols-5 sm:pt-6'>
              <div className='mrcool-product-dropdown-header flex w-full items-center justify-between ml-3 p-4 sm:items-start sm:border-none sm:p-0'>
                <h1 className='mrcool-product-dropdown-title text-2xl font-semibold'>
                  {dataProduct?.header?.name || ''}
                </h1>
                <button
                  onClick={() =>
                    onOpenDrawer(dataProduct?.header?.id, dataProduct?.header)
                  }
                  className='mrcool-product-dropdown-close-btn flex items-center justify-center rounded-lg border p-1 text-sm text-gray-700 focus:outline-none sm:hidden'
                >
                  <MdClose className='size-4' />
                </button>
              </div>
              <div className='mrcool-product-dropdown-products h-full overflow-auto px-4 sm:col-span-4'>
                <div className='mrcool-product-dropdown-grid grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-5'>
                  {
                    dataProduct?.productDropdown &&
                    dataProduct?.productDropdown?.length > 0
                      ? dataProduct?.productDropdown?.map((product: any) => {
                          return (
                            <Link
                              key={product?.id}
                              title={product?.attributes?.name}
                              className='relative h-auto min-h-44 w-full overflow-hidden rounded-xl bg-[#F7F8F9] p-4'
                              // onClick={() => router.push(`/product/${product?.attributes?.slug}`)}
                              href={`/product/${product?.attributes?.slug}`}
                            >
                              <div className='flex h-full w-full flex-col items-center justify-start'>
                                <img
                                  className='h-[150px] w-full rounded-xl rounded-b-none object-cover object-center p-2'
                                  src={
                                    product?.attributes?.thumbnail?.data
                                      ?.attributes?.url
                                      ? product?.attributes?.thumbnail?.data
                                          ?.attributes?.url
                                      : `/images/air-conditioner.png`
                                  }
                                  alt='Image Description'
                                />
                                <p className='text-center text-[15px] font-medium'>
                                  {product?.attributes?.name}
                                </p>
                              </div>
                            </Link>
                          );
                        })
                      : null
                  }
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}

      {/* All products dropdown modal */}
      {dataProduct && dataProduct?.id === 'all' ? (
        <ModalComponent
          isOpen={dataProduct}
          close={() => onOpenDrawer('all')}
          className='mrcool-all-products-modal z-1000 min-h-full rounded-b-none rounded-t-none sm:min-h-fit'
          containerClass='h-full sm:max-h-full'
          size={'full'}
          position='top'
          transition={width > 620 ? 'top' : 'left'}
          style={{
            top: clientHeight,
          }}
        >
          <div className='mrcool-all-products-content w-full'>
            <div className='mrcool-all-products-grid relative mx-auto grid h-full w-full max-w-[85rem] grid-cols-1 gap-4 pb-6 sm:grid-cols-5 sm:pt-6'>
              <div className='mrcool-all-products-categories flex w-full flex-col gap-2 border-b p-4 sm:border-none sm:p-0 pr-8 ml-3'>
                <div className='flex w-full items-center justify-between sm:items-start'>
                  <h1 className='text-md font-semibold'>Categories</h1>
                  <button
                    onClick={() => onOpenDrawer('all')}
                    className='flex items-center justify-center rounded-lg border p-1 text-sm text-gray-700 focus:outline-none sm:hidden'
                  >
                    <MdClose className='size-4' />
                  </button>
                </div>
                <div className='h-[150px] w-full overflow-auto sm:h-auto'>
                  {productCategories?.length > 0 &&
                    productCategories?.map((cat) => {
                      return (
                        <button
                          key={cat?.id}
                          className={`mb-2 inline-block w-full rounded px-3 py-2 text-left duration-500 hover:bg-gray-100 ${cat?.attributes?.slug === isSelectedCategory ? 'bg-gray-100' : ''}`}
                          onClick={() =>
                            setIsSelectedCategory(cat?.attributes?.slug)
                          }
                        >
                          {cat?.attributes?.name || ''}
                        </button>
                      );
                    })}
                </div>
              </div>
              <div className='mrcool-all-products-list h-full overflow-auto px-4 sm:col-span-4'>
                <h1 className='mrcool-all-products-title text-md mb-2 font-semibold'>Products</h1>
                <div className='mrcool-all-products-grid grid grid-cols-2 gap-5 sm:grid-cols-3 lg:grid-cols-5'>
                  {isLoadingCategories ? (
                    <button
                      title='loading'
                      className='relative h-auto min-h-44 w-full overflow-hidden rounded-xl'
                    >
                      <div className='my-auto flex h-[200px] w-full flex-col items-center justify-center overflow-auto'>
                        <div className='flex h-full w-full animate-pulse bg-gray-100 text-gray-600'>
                          <div className='m-auto'>Loading...</div>
                        </div>
                      </div>
                    </button>
                  ) : productSelected && productSelected?.length > 0 ? (
                    productSelected?.map((product: any) => {
                      return (
                        <Link
                          key={product?.id}
                          title={product?.attributes?.name}
                          className='relative h-auto min-h-44 w-full overflow-hidden rounded-xl bg-[#F7F8F9] p-4'
                          // onClick={() => router.push(`/product/${product?.attributes?.slug}`)}
                          href={`/product/${product?.attributes?.slug}`}
                        >
                          <div className='flex h-full w-full flex-col items-center justify-start overflow-hidden'>
                            <img
                              className='h-[150px] w-full rounded-xl rounded-b-none object-cover object-center p-2'
                              src={
                                product?.attributes?.thumbnail?.data?.attributes
                                  ?.url
                                  ? product?.attributes?.thumbnail?.data
                                      ?.attributes?.url
                                  : `/images/air-conditioner.png`
                              }
                              alt='Image Description'
                            />
                            <p className='text-center text-[15px] font-medium'>
                              {product?.attributes?.name}
                            </p>
                          </div>
                        </Link>
                      );
                    })
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}

      {/* Search modal */}
      <ModalComponent
        isOpen={isOpen}
        close={onClose}
        className='mrcool-search-modal rounded-t-none'
        containerClass=''
        size='md'
        position='top'
        transition='top'
      >
        <div className='mrcool-search-input-container relative w-full'>
          <input
            type='text'
            id='hs-search-box-with-loading-1'
            name='hs-search-box-with-loading-1'
            className='mrcool-search-input block w-full border-0 border-gray-200 px-4 py-4 text-sm shadow-sm focus:z-10 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 sm:py-5 sm:ps-11'
            placeholder='Search MRCOOL®'
            autoFocus
            value={inputValue || ''}
            onChange={handleInputChange}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                router.push(
                  `/components/searchResult?key=${inputValue}&tag=product`
                );
                onClose();
              }
            }}
          />
          <div
            className={`pointer-events-none absolute inset-y-0 start-0 flex items-center ps-4`}
          >
            {isLoading ? (
              <div
                className='inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-current border-t-transparent text-gray-500'
                role='status'
                aria-label='loading'
              >
                <span className='sr-only'>Loading...</span>
              </div>
            ) : (
              <Icon
                icon={MdSearch}
                className={`hidden h-5 w-5 text-gray-500 sm:block`}
              />
            )}
          </div>

          <div className='mrcool-search-close-btn-container absolute inset-y-0 end-0 z-[80] flex items-center pe-4'>
            <button
              className='mrcool-search-close-btn'
              type='button'
              onClick={!inputValue ? onClose : clearInput}
            >
              <Icon icon={MdClose} className='h-5 w-5 text-inherit' />
            </button>
          </div>
        </div>

        <div className='mrcool-search-results overflow-y-auto py-2'>
          <div className='mrcool-search-results-content mb-3 w-full text-sm'>
            <div className='mrcool-search-results-container px-4'>
              <h1 className='mrcool-search-results-title sticky top-0 z-10 bg-white py-4 font-semibold'>
                {dataProducts.length > 0 && 'Product'}
              </h1>
              <div className='mrcool-search-results-list flex w-full flex-col'>
                {dataProducts.length > 0
                  ? dataProducts?.map((product) => {
                      return (
                        <button
                          key={product?.id}
                          className='inline-block px-4 py-1 text-left text-gray-600 hover:text-primary hover:underline'
                          data-hs-overlay='#hs-search-modal'
                          onClick={() => {
                            router.push(
                              `/product/${product?.attributes?.slug}`
                            );
                          }}
                        >
                          {product?.attributes?.name}
                        </button>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
        </div>
      </ModalComponent>
    </Fragment>
  );
};

export default Navbar;