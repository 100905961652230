import { create } from 'zustand';
import AxiosInstance, {
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
} from 'axios';
import { ResultNavigation } from '../types';

interface NavigationState {
  navigations: ResultNavigation;
  isLoading: boolean;
  isError: boolean;
  getNavigations: (params: any) => Promise<void>;
}

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

export const prefix = '/header-navigation';

export const useNavigation = create<NavigationState>((set) => ({
  navigations: {
    id: null,
    attributes: {
      createdAt: null,
      updatedAt: null,
      list: [],
    },
  },
  isLoading: false,
  isError: false,
  getNavigations: async (params?: AxiosRequestConfig) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(prefix, params);
      set({ navigations: response.data.data });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching header-navigation:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
}));
